import { mapStores } from "pinia";
import { v4 as uuidv4 } from "uuid";
import ConfigDBManager from '../utils/configDBManager.js';
import MetricManager from '../utils/metricManager.js';

import CPEngineWorker from "../../cp_socket/cp_socket.worker.js";
import {
    getOutageMaintenanceCount,
    getPendingServers,
} from "../../api/services/UtilService.js";
import { useUserStore } from "../../stores/userStore";
import { useCountStore } from '../../stores/countStore.js';
import { useAppStore } from '../../stores/appStore.js';

const id = uuidv4();

const Worker = new CPEngineWorker();

const rootVueMixin = {
    data() {
        return {
            navItems: [],
            userImage: {},
            userItems: [],
            nav_items: [],
            user_nav_items: [],
            show_instance_drawer: true,
            instance_drawer_open: false,
            customerConfig: {
                allTags: [],
            },
            outageMaintenanceCountInterval: null,
            pendingInstancesInterval: null,
            cpSocketState: WebSocket.CONNECTING,
        };
    },

    computed: {
        ...mapStores(useUserStore, useCountStore, useAppStore),
    },

    methods: {
        open_instance_drawer() {
            this.instance_drawer_open = true;
        },

        close_instance_drawer() {
            this.instance_drawer_open = false;
        },

        enableMetricManager() {
            this.metricManager = new MetricManager();
        },
        async fetchOutageMaintenanceCount() {
            try {
                const { data, status } = await getOutageMaintenanceCount();

                // Even though its a login redirect the response.status is 200
                // Lets check if the response is a html string and contains the login
                // component, if so refresh the page.
                if (
                    (typeof data === 'string' || data instanceof String) &&
                    data.includes('</p-auth>')
                ) {
                    window.location.reload();
                }

                if (status === 200) {
                    this.countStore.updateCount("activeIncidents", data.num_outages);
                    this.countStore.updateCount("activeUpcomingMaintenance", data.num_maintenance);
                }
            } catch (error) {
                console.error(error);
            }
        },
        async fetchPendingInstances() {
            try {
                const { data } = await getPendingServers();
                if (data.success) {
                    this.countStore.updateCount("pendingInstances", data.data.count);
                }
            } catch (error) {
                console.error(error);
            }
        },
        async setupAjaxPolling() {
            await this.fetchOutageMaintenanceCount();
            await this.fetchPendingInstances();

            this.outageMaintenanceCountInterval = setInterval(async () => {
                await this.fetchOutageMaintenanceCount();
            }, 5000);

            this.pendingInstancesInterval = setInterval(async () => {
                await this.fetchPendingInstances();
            }, 60000);
        },
        clearAjaxPolling() {
            clearInterval(this.outageMaintenanceCountInterval);
            clearInterval(this.pendingInstancesInterval);
            this.outageMaintenanceCountInterval = null;
            this.pendingInstancesInterval = null;
        },
        handleBeforeUnload() {
            this.clearAjaxPolling();
        },
        sendMessageToWorker(message) {
            Worker.port.postMessage(message);
        },
        handleBroadcastMessage(event) {
            console.log('[Broadcast]', event.data);
            switch (event.data.type) {
                case "CPSocket":
                    if (event.data.action === "message") {
                        switch (event.data.payload.type) {
                            case "infraCount":
                                Object.entries(event.data.payload.data).forEach(([countName, countValue]) => {
                                    this.countStore.updateCount(countName, countValue);
                                });
                                break;
                            case "WSState":
                                this.cpSocketState = event.data.state;
                                break;
                            default:
                                break;
                        }
                    }
                    break;
            }
        },
    },

    beforeDestroy() {
        window.removeEventListener("beforeunload", this.handleBeforeUnload);
    },

    async mounted() {
        window.addEventListener("beforeunload", this.handleBeforeUnload);

        window.app.eventHub.$emit('rootVue:ready', this);

        if (this.userStore.currentUser) {
            if (this.userStore.hasFeature("feature.cp_websocket")) {
                if (Worker && Worker.port) {
                    Worker.port.start();

                    Worker.port.onmessage = (event) => {
                        switch (event.data.type) {
                            case "WorkerState":
                                if (event.data.state === "ready") {
                                    this.sendMessageToWorker({
                                        type: "RegisterConnection",
                                        payload: { id },
                                    });
                                } else if (event.data.state === "registered") {
                                    const currentState = event.data.payload;
                                    const nextUrl = `${`${this.appStore.config.cpEngineUrl}?c=${this.appStore.config.sessionId}`}`;
                                    const nextUserId = this.userStore.currentUser.id;

                                    if (
                                        !currentState.readyState ||
                                        nextUrl !== currentState.url ||
                                        nextUserId !== currentState.userId
                                    ) {
                                        this.sendMessageToWorker({
                                            type: "ConnectWebSocket",
                                            payload: {
                                                url: nextUrl,
                                                userId: nextUserId,
                                            },
                                        });
                                    }
                                }
                                break;
                            case "WSState":
                                console.log(`WSState - ${event.data.state}`);
                                cpSocketState = event.data.state;
                                break;
                            default:
                                console.error(`No handler for event type: '${event.data.type}'`);
                                break;
                        }
                    };

                    const broadcastChannel = new BroadcastChannel("CPSocketChannel");
                    broadcastChannel.onmessage = (event) => this.handleBroadcastMessage(event);
                }
            } else {
                await this.setupAjaxPolling();
            }

            this.configDBManager = new ConfigDBManager({
                parent: this,
            });
            this.configDBManager.$on('configdb:update', config => {
                for (const key in config) {
                    if (config.hasOwnProperty(key)) {
                        Vue.set(this.customerConfig, key, config[key]);
                    }
                }
                // Notify eventHub of the update
                this.eventHub.$emit('customerConfig:update');
            });

            if (window.openMyAccount) {
                // Open the My Account drawer on load
                window.setTimeout(() => {
                    this.$broadcast('drawer:load', {
                        id: 'dynamic-drawer',
                        url: '/userconfig/EditUser?user_id=-1&show_email_prefs=1',
                        anonymous: false,
                        width: 450,
                    });
                }, 500);
            }

            document.addEventListener('DOMContentLoaded', () => {
                if (!this.nav_items.length && window.navItems) {
                    this.nav_items = window.navItems;
                }
                if (!this.user_nav_items.length && window.userItems) {
                    this.user_nav_items = window.userItems;
                }
            });

            $.ajax({
                url: '/config/getTopNav',
                type: 'GET',
            }).done(data => {
                if (data.success && data.menu) {
                    if (data.menu.length) {
                        const userItems = data.menu.pop().items;
                        this.user_nav_items = userItems;
                    }
                    this.nav_items = data.menu;
                }
            });
        }
    },
};

export default rootVueMixin;
