class LocalStorageManager {
    constructor() {
        this.storage = window.localStorage;
        console.debug('LocalStorageManager initialized');
    }

    // In each getter, return a sensible default that
    // still evaluates to false
    getString(textkey, defaultValue = '') {
        const value = this.storage.getItem(textkey);
        if (value === null) {
            return defaultValue;
        }
        return value;
    }

    getObject(textkey, defaultValue = null) {
        const value = this.storage.getItem(textkey);
        if (value === null) {
            return defaultValue;
        }
        try {
            const parsed = JSON.parse(value);
            return parsed;
        } catch (e) {
            console.error(`Invalid JSON: ${value}`);
            return defaultValue;
        }
    }

    getNumber(textkey, defaultValue = null) {
        const value = this.storage.getItem(textkey);
        if (value === null) {
            return defaultValue;
        }
        try {
            const parsed = Number(value);
            return parsed;
        } catch (e) {
            console.error(`Invalid Number: ${value}`);
            return defaultValue;
        }
    }

    setString(textkey, string) {
        this.storage.setItem(textkey, string);
    }

    setObject(textkey, inObject) {
        try {
            const stringified = JSON.stringify(inObject);
            this.storage.setItem(textkey, stringified);
        } catch (e) {
            console.error(e);
        }
    }

    setNumber(textkey, inNumber) {
        try {
            const stringified = String(inNumber);
            this.storage.setItem(textkey, stringified);
        } catch (e) {
            console.error(e);
        }
    }
}

export default LocalStorageManager;
