import { reactive, watch } from "vue";
import { defineStore } from "pinia";
import { COUNT_STORE_KEY } from "../shared/constants/storeKeys";

export const useCountStore = defineStore(COUNT_STORE_KEY, () => {
    const counts = reactive({
        activeIncidents: 0,
        activeUpcomingMaintenance: 0,
        pendingInstances: 0,
    });
    const savedCounts = window.app.localStorageManager.getObject("countStore", {});

    const updateCount = (name, value) => {
        counts[name] = value;
    };

    for (const countName in savedCounts) {
        if (savedCounts.hasOwnProperty(countName)) {
            const value = savedCounts[countName];
            updateCount(countName, value);
        }
    }

    watch(() => ({...counts}), () => {
        window.app.localStorageManager.setObject("countStore", counts);
    });

    watch(() => counts.pendingInstances, (pendingInstancesCount) => {
        // TODO: Once the instance tree is moved to a vue component, we dont need to do it this way
        window.app.rootVue.$broadcast('pendingServerCount:change', pendingInstancesCount);
    });

    return { counts, updateCount };
});
