import { ref, computed } from "vue";
import { defineStore } from "pinia";
import { APP_STORE_KEY } from "../shared/constants/storeKeys";
import { matchesWindowUrl } from "../navigation/shared/navUtils";

const isItemActive = (item) => {
    return matchesWindowUrl(item.href, item.to);
};

export const useAppStore = defineStore(APP_STORE_KEY, () => {
    const isDev = ref(process.env.NODE_ENV === "development");
    const navItems = ref(window.navItems);
    const breadcrumbs = ref(window.breadcrumbs);
    const config = ref({
        sessionId: window._VUE_SERVER_DATA_.appConfig.sessionId,
        cpEngineUrl: window._VUE_SERVER_DATA_.appConfig.cpEngineUrl,
        swaggerDocsBaseUrl: window._VUE_SERVER_DATA_.appConfig.swaggerDocsBaseUrl,
    });

    const computedNavItems = computed(() => {
        return navItems.value.map((navItem) => {
            const isActive = isItemActive(navItem);
            const items = navItem.items ? navItem.items.map((item) => {
                const secondaryNavItems = item.secondary_nav_items
                    ? item.secondary_nav_items.map((secondaryNavItem) => ({
                        ...secondaryNavItem,
                        active: isItemActive(secondaryNavItem),
                    }))
                    : [];
                const secondaryActive = secondaryNavItems.some(
                    secondaryNavItem => secondaryNavItem.active
                );

                return {
                    ...item,
                    active: isItemActive(item),
                    secondaryActive,
                    secondary_nav_items: secondaryNavItems,
                };
            }) : [];
            const hasActiveChild = items.some(item => item.active || item.secondaryActive);

            return {
                ...navItem,
                active: isActive,
                items,
                hasActiveChild,
            };
        });
    });

    const activeNavItem = computed(() => {
        return computedNavItems.value.find(item => item.active || item.hasActiveChild);
    });

    const activeSecondaryNavItem = computed(() => {
        if (!activeNavItem.value.items || !activeNavItem.value.items.length) {
            return null;
        }

        return activeNavItem.value.items.find(subItem => subItem.secondaryActive);
    });

    const secondaryNav = computed(() => {
        if (!activeSecondaryNavItem.value) {
            return null;
        }

        return activeSecondaryNavItem.value.secondary_nav_items;
    });

    return {
        isDev,
        config,
        breadcrumbs,
        navItems: computedNavItems,
        activeNavItem,
        activeSecondaryNavItem,
        secondaryNav,
    };
});
