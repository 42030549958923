const PublicProbes = () => import(
    /* webpackChunkName: "PublicProbes" */
    '../public_probes/PublicProbes.vue'
);
const Users = () => import(
    /* webpackChunkName: "Users" */
    '../users/Users.vue'
);
const EditRotatingSchedule = () => import(
    /* webpackChunkName: "EditRotatingSchedule" */
    '../users/EditRotatingSchedule.vue'
);
const EditContactGroup = () => import(
    /* webpackChunkName: "EditContactGroup" */
    '../users/EditContactGroup.vue'
);
const ApplianceDetails = () => import(
    /* webpackChunkName: "ApplianceDetails" */
    '../report/ApplianceDetails.vue'
);
const ServerGroup = () => import(
    /* webpackChunkName: "ServerGroup" */
    '../report/ServerGroup.vue'
);
const KubernetesEvents = () => import(
    /* webpackChunkName: "KubernetesEvents" */
    '../report/KubernetesEvents.vue'
);
const EditCompoundMetric = () => import(
    /* webpackChunkName: "EditCompoundMetric" */
    '../report/EditCompoundMetric.vue'
);
const AddSdWanTarget = () => import(
    /* webpackChunkName: "AddSdWanTarget" */
    '../report/AddSdWanTarget.vue'
);
const ApplicationsCatalog = () => import(
    /* webpackChunkName: "ApplicationsCatalog" */
    '../applications/ApplicationsCatalog.vue'
);
const ApplicationsOverview = () => import(
    /* webpackChunkName: "ApplicationsOverview" */
    '../applications/ApplicationsOverview.vue'
);
const ApplicationDetails = () => import(
    /* webpackChunkName: "ApplicationDetails" */
    '../applications/ApplicationDetails.vue'
);
const CustomApplicationForm = () => import(
    /* webpackChunkName: "CustomApplicationForm" */
    '../applications/CustomApplicationForm.vue'
);
const InfraMapManager = () => import(
    /* webpackChunkName: "InfraMapManager" */
    '../infra_map/components/InfraMapManager.vue'
);
const Home = () => import(
    /* webpackChunkName: "Home" */
    '../dashboards/Home.vue'
);
const ListCredentials = () => import(
    /* webpackChunkName: "ListCredentials" */
    '../report/ListCredentials.vue'
);
const CreateDashboard = () => import(
    /* webpackChunkName: "CreateDashboard" */
    '../dashboards/CreateDashboard.vue'
);
const ListCountermeasures = () => import(
    /* webpackChunkName: "ListCountermeasures" */
    '../config/ListCountermeasures.vue'
);
const NetflowOverview = () => import(
    /* webpackChunkName: "NetflowOverview" */
    '../netflow/NetflowOverview.vue'
);
const TopologyOverview = () => import(
    /* webpackChunkName: "TopologyOverview" */
    '../topology/TopologyOverview.vue'
);
const EditPublicReportV2 = () => import(
    /* webpackChunkName: "EditPublicReportV2" */
    "../config/EditPublicReportV2.vue"
);
const ListTags = () => import(
    /* webpackChunkName: "ListTags" */
    "../config/ListTags.vue"
);
const ListAttributes = () => import(
    /* webpackChunkName: "ListAttributes" */
    "../config/ListAttributes.vue"
);
const MergeTags = () => import(
    /* webpackChunkName: "MergeTags" */
    "../config/MergeTags.vue"
);
const ApiKeyManagement = () => import(
    /* webpackChunkName: "ApiKeyManagement" */
    '../report/ApiKeyManagement.vue'
);
const Usage = () => import(
    /* webpackChunkName: "Usage" */
    '../billing/Usage.vue'
);
const GlobalSearch = () => import(
    /* webpackChunkName: "GlobalSearch" */
    "../report/GlobalSearch.vue"
);
const ListReports = () => import(
    /* webpackChunkName: "ListReports" */
    "../report/ListReports.vue"
);
const ListMaintenanceSchedules = () => import(
    /* webpackChunkName: "ListMaintenanceSchedules" */
    '../config/ListMaintenanceSchedules.vue'
);
const ListActiveUpcomingMaintenanceSchedules = () => import(
    /* webpackChunkName: "ListActiveUpcomingMaintenanceSchedules" */
    '../config/ListActiveUpcomingMaintenanceSchedules.vue'
);
const ListVMWareCredentials = () => import(
    /* webpackChunkName: "ListVMWareCredentials" */
    '../vmware/ListVMWareCredentials.vue'
);
const ListCustomMetrics = () => import(
    /* webpackChunkName: "ListCustomMetrics" */
    '../config/ListCustomMetrics.vue'
);
const ListStatusReports = () => import(
    /* webpackChunkName: "ListStatusReports" */
    '../config/ListStatusReports.vue'
);
const NotFound = () => import(
    /* webpackChunkName: "NotFound" */
    '../shared/components/NotFound.vue'
);
const VendorMIBs = () => import(
    /* webpackChunkName: "VendorMIBs" */
    '../config/VendorMIBs.vue'
);
const EditCustomPerfmonMetrics = () => import(
    /* webpackChunkName: "EditCustomPerfmonMetrics" */
    '../config/EditCustomPerfmonMetrics.vue'
);
const EditNotificationSchedule = () => import(
    /* webpackChunkName: "EditNotificationSchedule" */
    '../config/EditNotificationSchedule.vue'
);
const ListNotificationSchedules = () => import(
    /* webpackChunkName: "ListNotificationSchedules" */
    '../config/ListNotificationSchedules.vue'
);
const OnboardingSplash = () => import(
    /* webpackChunkName: "OnboardingSplash" */
    "../onboarding/Splash.vue"
);
const CustomOnboarding = () => import(
    /* webpackChunkName: "CustomOnboarding" */
    "../custom_obf/CustomOnboarding.vue"
);
const IncidentOverview = () => import(
    /* webpackChunkName: "IncidentOverview" */
    "../incident/IncidentOverview.vue"
);
const IncidentDetails = () => import(
    /* webpackChunkName: "IncidentDetails" */
    "../outage/IncidentDetails.vue"
);
const IncidentSolutions = () => import(
    /* webpackChunkName: "IncidentSolutions" */
    "../outage/IncidentSolutionsPage.vue"
);
const InstanceDetails = () => import(
    /* webpackChunkName: "InstanceDetails" */
    "../instance_details/InstanceDetails.vue"
);
const ListCloudProviders = () => import(
    /* webpackChunkName: "ListCloudProviders" */
    "../cloud/ListCloudProviders.vue"
);
const PolicyWorkflow = () => import(
    /* webpackChunkName: "PolicyWorkflow" */
    "../monitoring_policy/PolicyWorkflow.vue"
);
const Integrations = () => import(
    /* webpackChunkName: "Integrations" */
    "../integrations/Integrations.vue"
);
const ServiceNowIMApp = () => import(
    /* webpackChunkName: "ServiceNowIMApp" */
    '../integrations/servicenow_im/ServiceNowIMApp.vue'
);
const WebhookApp = () => import(
    /* webpackChunkName: "WebhookApp" */
    '../integrations/webhooks/WebhookApp.vue'
);
const ZendeskApp = () => import(
    /* webpackChunkName: "ZendeskApp" */
    '../integrations/zendesk/ZendeskApp.vue'
);
const SalesforceApp = () => import(
    /* webpackChunkName: "SalesforceApp" */
    '../integrations/salesforce/SalesforceApp.vue'
);
const JiraSDApp = () => import(
    /* webpackChunkName: "JiraSDApp" */
    '../integrations/jira_sd/JiraSDApp.vue'
);
const MsTeamsApp = () => import(
    /* webpackChunkName: "MsTeamsApp" */
    '../integrations/ms_teams/MsTeamsApp.vue'
);
const OnSightManagementController = () => import(
    /* webpackChunkName: "OnSightManagementController" */
    '../onsight_management/OnSightManagementController.vue'
);
const FabricListConnections = () => import(
    /* webpackChunkName: "FabricListConnections" */
    '../fabric/FabricListConnections.vue'
);
const BulkUpload = () => import(
    /* webpackChunkName: "BulkUpload" */
    '../config/BulkUpload.vue'
);
const RoleEdit = () => import(
    /* webpackChunkName: "RoleEdit" */
    '../role/RoleEdit.vue'
);
const RoleList = () => import(
    /* webpackChunkName: "RoleList" */
    '../role/RoleList.vue'
);
const RoleView = () => import(
    /* webpackChunkName: "RoleView" */
    '../role/RoleView.vue'
);


import { useUserStore } from "../stores/userStore";

import { getTopologyActivities } from '../api/services/TopologyService.js';

export const routes = [
    {
        path: "/onboarding/v2",
        name: "onboarding",
        component: OnboardingSplash,
    },
    {
        path: "/onboarding/v2/*",
        name: "onboarding",
        component: OnboardingSplash,
    },
    {
        path: "/onboarding/CustomOnboarding",
        name: "custom-onboarding",
        component: CustomOnboarding,
    },
    {
        path: "/users",
        name: "users",
        component: Users,
    },
    {
        path: '/onsight_management',
        name: 'onsight-management',
        component: OnSightManagementController,
    },
    {
        path: '/incidents',
        name: 'incidents',
        component: IncidentOverview,
    },
    {
        path: '/integrations',
        name: 'integrations',
        component: Integrations,
    },
    {
        path: "/config/EditServiceNowIM",
        name: "edit-service-now",
        component: ServiceNowIMApp,
    },
    {
        path: "/config/EditWebhook",
        name: "edit-webhook",
        component: WebhookApp,
    },
    {
        path: "/config/EditZendesk",
        name: "edit-zendesk",
        component: ZendeskApp,
    },
    {
        path: "/config/EditSalesforce",
        name: "edit-salesforce",
        component: SalesforceApp,
    },
    {
        path: "/config/EditJiraSD",
        name: "edit-jira",
        component: JiraSDApp,
    },
    {
        path: "/config/EditMsTeams",
        name: "edit-ms-teams",
        component: MsTeamsApp,
    },
    {
        path: '/monitoring_policy',
        name: 'monitoring-policy',
        component: PolicyWorkflow,
    },
    {
        path: '/cloud/ListProviders',
        name: 'list-cloud-providers',
        component: ListCloudProviders,
    },
    {
        path: '/report/ListServers',
        name: 'list-servers',
        component: ServerGroup,
    },
    {
        path: '/report/ServerGroupReport',
        name: 'server-group-report',
        component: ServerGroup,
    },
    {
        path: '/users/on_call/EditRotatingSchedule',
        name: 'edit-rotating-schedule',
        component: EditRotatingSchedule,
    },
    {
        path: '/report/KubernetesEvents',
        name: 'kubernetes-events',
        component: KubernetesEvents,
    },
    {
        path: '/report/EditCompoundMetric',
        name: 'edit-compound-metric',
        component: EditCompoundMetric,
    },
    {
        path: '/report/AddSdWanTarget',
        name: 'add-sdwan-target',
        component: AddSdWanTarget,
    },
    {
        path: '/report/InstanceDetails',
        name: 'instance-details',
        component: InstanceDetails,
    },
    {
        path: '/outage/IncidentDetails',
        name: 'incident-details',
        component: IncidentDetails,
    },
    {
        path: '/outage/IncidentSolutions',
        name: 'incident-solutions',
        component: IncidentSolutions,
    },
    {
        path: '/billing',
        name: 'billing',
        component: Usage,
    },
    {
        path: '/application',
        redirect: '/application/overview',
        name: "applications-overview",
        component: ApplicationsOverview,
    },
    {
        path: "/application/overview",
        name: "applications-overview",
        component: ApplicationsOverview,
    },
    {
        path: "/application/catalog",
        name: "applications-catalog",
        component: ApplicationsCatalog,
    },
    {
        path: "/application/add/custom-application",
        name: "custom-application-form",
        component: CustomApplicationForm,
    },
    {
        path: "/application/:applicationId",
        redirect: "/application/:applicationId/overview",
        name: "application-details",
        component: ApplicationDetails,
    },
    {
        path: "/application/:applicationId/monitoring",
        redirect: "/application/:applicationId/monitoring/performance",
        component: ApplicationDetails,
    },
    {
        path: "/application/:applicationId/:tab/:subTab?",
        name: "application-details",
        component: ApplicationDetails,
    },
    {
        path: "/application/:applicationId/:tab/:subTab/:actionTab?",
        name: "application-details",
        component: ApplicationDetails,
    },
    {
        path: "/config/EditPublicReportV2",
        name: "edit-public-report-v2",
        component: EditPublicReportV2,
    },
    {
        path: "/application/*",
        name: "not-found",
        component: NotFound,
    },
    {
        path: "/config/BulkUpload",
        name: "bulk-import",
        component: BulkUpload,
    },
    {
        path: "/dashboard/home",
        name: "dashboards-home",
        component: Home,
    },
    {
        path: "/fabric/ListConnections",
        name: "fabric-list-connections",
        component: FabricListConnections,
    },
    {
        path: "/config/ListAttributes",
        name: "list-attributes",
        component: ListAttributes,
    },
    {
        path: "/config/ListTags",
        name: "list-tags",
        component: ListTags,
    },
    {
        path: "/config/VendorMIBs",
        name: "vendor-mibs",
        component: VendorMIBs,
    },
    {
        path: "/config/EditCustomPerfmonMetrics",
        name: "edit-custom-perf-metrics",
        component: EditCustomPerfmonMetrics,
    },
    {
        path: "/config/notification/EditNotificationSchedule",
        name: "edit-notification-schedule",
        component: EditNotificationSchedule,
    },
    {
        path: "/config/notification/ListNotificationSchedules",
        name: "list-notification-schedules",
        component: ListNotificationSchedules,
    },
    {
        path: "/config/ListCountermeasures",
        name: "list-countermeasures",
        component: ListCountermeasures,
    },
    {
        path: "/config/ListStatusReports",
        name: "list-status-reports",
        component: ListStatusReports,
    },
    {
        path: "/config/ListMaintenanceSchedules",
        name: "list-maintenance",
        component: ListMaintenanceSchedules,
    },
    {
        path: "/config/ListActiveUpcomingMaintenanceSchedules",
        name: "list-active-upcoming-maintenance",
        component: ListActiveUpcomingMaintenanceSchedules,
    },
    {
        path: "/vmware/ListCredentials",
        name: "list-vmware-credentials",
        component: ListVMWareCredentials,
    },
    {
        path: "/netflow",
        redirect: '/netflow/summary',
        name: "netflow-overview",
        component: NetflowOverview,
    },
    {
        path: "/netflow/:tab",
        name: "netflow-overview",
        component: NetflowOverview,
    },
    {
        path: "/users/groups/EditContactGroup",
        name: "edit-contact-group",
        component: EditContactGroup,
    },
    {
        path: "/dashboardv2/renderDashboard",
        name: "dashboards",
        component: CreateDashboard,
    },
    {
        path: "/infra_map",
        name: "infra-map",
        component: InfraMapManager,
    },
    {
        path: "/config/MergeTags",
        name: "merge-tags",
        component: MergeTags,
    },
    {
        path: "/config/ListCustomMetrics",
        name: "list-custom-metrics",
        component: ListCustomMetrics,
    },
    {
        path: "/report/ApiKeyManagement",
        name: "api-key-management",
        component: ApiKeyManagement,
    },
    {
        path: "/report/GlobalSearch",
        name: "global-search",
        component: GlobalSearch,
    },
    {
        path: "/report/ListCredentials",
        name: "list-credentials",
        component: ListCredentials,
    },
    {
        path: "/report/NetflowAppliance/:applianceId",
        redirect: "/report/NetflowAppliance/:applianceId/general",
        name: "appliance-details",
        component: ApplianceDetails,
    },
    {
        path: "/report/NetflowAppliance/:applianceId/monitoring",
        redirect: "/report/NetflowAppliance/:applianceId/monitoring/metrics",
        name: "appliance-details",
        component: ApplianceDetails,
    },
    {
        path: "/report/NetflowAppliance/:applianceId/:tab",
        name: "appliance-details",
        component: ApplianceDetails,
    },
    {
        path: "/report/NetflowAppliance/:applianceId/:tab/:subtab?",
        name: "appliance-details",
        component: ApplianceDetails,
    },
    {
        path: "/report/Appliance/:applianceId",
        redirect: "/report/Appliance/:applianceId/general",
        name: "appliance-details",
        component: ApplianceDetails,
    },
    {
        path: "/report/Appliance/:applianceId/monitoring",
        redirect: "/report/Appliance/:applianceId/monitoring/metrics",
        name: "appliance-details",
        component: ApplianceDetails,
    },
    {
        path: "/report/Appliance/:applianceId/:tab",
        name: "appliance-details",
        component: ApplianceDetails,
    },
    {
        path: "/report/Appliance/:applianceId/:tab/:subtab?",
        name: "appliance-details",
        component: ApplianceDetails,
    },
    {
        path: "/topology",
        name: "topology-overview",
        component: TopologyOverview,
        beforeEnter: async (to, _, next) => {
            const { data } = await getTopologyActivities();

            if (data.success) {
                to.meta.activities = data.activities;
            }

            next();
        },
    },
    {
        path: "/report/ListReports",
        name: "list-reports",
        component: ListReports,
    },
    {
        path: "/public_probes",
        name: "public-probes",
        component: PublicProbes,
    },
    {
        path: "/roles",
        name: "role-list",
        component: RoleList,
        beforeEnter: (to, from, next) => {
            let userStore = useUserStore();
            if (!userStore.canPerform('settings.acl.view')) {
                next({ name: "infra-map" });
            } else next();
        },
    },
    {
        path: "/roles/update",
        query: { "id": "cluster_id" },
        name: "role-edit",
        component: RoleEdit,
        beforeEnter: (to, from, next) => {
            let userStore = useUserStore();
            if (!userStore.canPerform('settings.acl.edit')) {
                next({ name: "role-list" });
            } else next();
        },
    },
    {
        path: "/roles/create",
        name: "role-create",
        component: RoleEdit,
        beforeEnter: (to, from, next) => {
            let userStore = useUserStore();
            if (!userStore.canPerform('settings.acl.edit')) {
                next({ name: "role-list" });
            } else next();
        },
    },
    {
        path: "/roles/read",
        query: { "id": "id" },
        name: "role-view",
        component: RoleView,
        beforeEnter: (to, from, next) => {
            let userStore = useUserStore();
            if (!userStore.canPerform('settings.acl.view')) {
                next({ name: "infra-map" });
            } else next();
        },
    },
];
